import React, { Suspense, lazy, useEffect, useState } from "react";
import { AdminPanel } from "services";
import { useTheme } from "srcm/hooks/useTheme";
import { WebsiteUnavailable } from "srcm/pages";
const AppDesktop = lazy(() => import("./AppDesktop"));
const AppMobile = lazy(() => import("./srcm/AppMobile"));
import {axiosInstance} from "./httpApi";

enum loadingStatus {
  loading = "loading",
  unworking = "unworking",
  working = "working",
}

async function logError(error: unknown) {
  try {
    await axiosInstance.post("/log-error", {
      error: String(error),
      url: window.location.href,
    });
  } catch (logError) {
    console.error("Failed to log error:", logError);
  }
}

function App() {
  const [status, setStatus] = useState(loadingStatus.loading);

  async function setSettings() {
    const locationLink = window.location.origin;
    try {
      const data = await AdminPanel.setCheckDomain({ url: locationLink });
      setStatus(data.data.status ? loadingStatus.unworking : loadingStatus.working);
    } catch (e) {
      console.error(e);
      await logError(e);
    }
  }

  useTheme();
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  useEffect(() => {
    try {
      setSettings();
    } catch (e) {
      console.error(e);
      logError(e);
    }
  }, []);

  return (
    <>
      {status === loadingStatus.loading && <div></div>}
      {status === loadingStatus.unworking && <WebsiteUnavailable />}
      {status === loadingStatus.working && (
        <Suspense fallback={<div></div>}>
          {(() => {
            try {
              return isMobile ? <AppMobile /> : <AppDesktop />;
            } catch (e) {
              console.error(e);
              logError(e);
              return <WebsiteUnavailable />;
            }
          })()}
        </Suspense>
      )}
    </>
  );
}

export default App;
