import React from "react";

import "./BannersBetzula.scss";
import { Link } from "react-router-dom";
import { useTypeSelector } from "hooks/useTypeSelector";

const BannersBetzula = () => {
  const language = useTypeSelector(
    (data) => data.settingsReducer.language.languageShort
  );
  let campaignId = 0;
  if (Comm100API && Comm100API.get) {
    const campaignIds = Comm100API.get("livechat.campaignIds");
    campaignId = campaignIds[0];
  }
  const handleOpenChat = () => {
    Comm100API?.do("livechat.button.click", campaignId);
  };
  return (
    <div className="hm-row-bc   " style={{ gridTemplateColumns: "12fr" }}>
      <div className="pb-component-wrapper">
        <div className="product-banner-container-bc col-4 ">
          <Link
            target="_self"
            className="product-banner-info-bc product-banner-bc"
            to={`/${language}/sports/pre-match/event-view`}
          >
            <i className="product-banner-icon-bc bc-i-sport" />
            <p className="product-banner-title-bc">SPOR</p>
          </Link>
          <Link
            target="_self"
            className="product-banner-info-bc product-banner-bc"
            to={`/${language}/sports/pre-match/event-view`}
          >
            <i className="product-banner-icon-bc bc-i-live" />
            <p className="product-banner-title-bc">CANLI BAHİS</p>
          </Link>
          <Link
            target="_self"
            className="product-banner-info-bc product-banner-bc"
            to={`/${language}/casino/slots/-1`}
          >
            <i className="product-banner-icon-bc bc-i-casino" />
            <p className="product-banner-title-bc">CASİNO</p>
          </Link>
          <Link
            target="_self"
            className="product-banner-info-bc product-banner-bc"
            to={`/${language}/live-casino/home/-1/All`}
          >
            <i className="product-banner-icon-bc bc-i-live-casino" />
            <p className="product-banner-title-bc">CANLI CASİNO</p>
          </Link>
          <div
            className="product-banner-info-bc product-banner-bc"
            onClick={() => handleOpenChat()}
          >
            <i className="product-banner-icon-bc bc-i-live-chat" />
            <p className="product-banner-title-bc">CANLI DESTEK</p>
          </div>
          <Link
            target="_self"
            className="product-banner-info-bc product-banner-bc"
            to="#"
          >
            <i className="product-banner-icon-bc bc-i-bonus-request" />
            <p className="product-banner-title-bc">BONUS TALEP</p>
          </Link>
          <Link
            target="_self"
            className="product-banner-info-bc product-banner-bc"
            to={`/${language}/promotions/all`}
          >
            <i className="product-banner-icon-bc bc-i-promotions" />
            <p className="product-banner-title-bc">PROMOSYONLAR</p>
          </Link>
          <Link
            target="_self"
            className="product-banner-info-bc product-banner-bc"
            to={`https://t.me/betkornertr`}
          >
            <i className="product-banner-icon-bc bc-i-telegram" />
            <p className="product-banner-title-bc">TELEGRAM</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export { BannersBetzula };
